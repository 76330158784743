@import '../../../../scss/theme-bootstrap';

.site-header-formatter {
  &__mobile-trigger:checked {
    .site-header-formatter__bar & {
      & ~ .site-header-formatter__sections {
        @include breakpoint($landscape-up) {
          padding-#{$ldirection}: 25px;
        }
      }
    }
  }
  &__header {
    .site-header-formatter__sections {
      @include breakpoint($landscape-up) {
        padding-#{$ldirection}: 25px;
      }
    }
    .site-header-formatter__logo {
      opacity: 0;
      @include breakpoint($landscape-up) {
        width: 0;
        margin-#{$ldirection}: 0;
      }
    }
  }
  &__visible-logo {
    .site-header-formatter__logo {
      opacity: 1;
      @include breakpoint($landscape-up) {
        width: 125px;
        margin-#{$ldirection}: 40px;
        margin-#{$rdirection}: 0;
      }
    }
    .site-header-formatter__sections {
      @include breakpoint($landscape-up) {
        padding-#{$ldirection}: 0;
      }
    }
  }
}